import Layout from "../components/layout"
import React from "react"
import BackMenu from "../components/backMenu"

import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"


const About = () => {
  const data = useStaticQuery(graphql`
    {
      directusAbout {
        name
        street
        zip_city
        country
        email
        phone
        cv {
          localFile {
            publicURL
          }
        }
        description
      }
    }
  `)
  console.log(data.directusAbout.cv.localFile.publicURL)
  return (
    <Layout>
      <div className="container mx-auto w-10/12 md:w-6/12 lg:w-5/12">
        <div className="xs:h-screen py-10 flex flex-col justify-center">
          <SEO title={data.directusAbout.name + " About"} />
          <div className="flex justify-left flex-col">
            <div className="font-semibold">{data.directusAbout.name}</div>
            <div>{data.directusAbout.street}</div>
            <div>{data.directusAbout.zip_city}</div>
            <div>{data.directusAbout.country}</div>
            
            
            <div className="mt-3 underline"><a href={"mailto:"+data.directusAbout.email}>{data.directusAbout.email}</a></div>
            <div>{data.directusAbout.phone}</div>

            <div className="italic mt-5">{data.directusAbout.description}</div>
            <div className="underline mt-2"><a href={data.directusAbout.cv.localFile.publicURL} download="ChristofferJoergensen.pdf">ChristofferJoergensen.pdf</a></div>
          </div>
        </div>
        <BackMenu/>
      </div>
    </Layout>
  )
}

export default About
